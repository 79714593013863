import React from "react";
import LooseDiamondsCountry from "../../components/FrontStatic/WholesaleLooseDiamonds/Country";
import {graphql} from "gatsby";
import {connect} from "react-redux";
import SEO from "../../components/SEO";

const CountryComponent = (props) => <React.Fragment>
    <SEO {...(props?.data?.seo?.frontmatter || {})}/>
    <LooseDiamondsCountry
    {...props}
    fields={props.data}
    country="UK"
/></React.Fragment>;

const mapStateToProps = (props) => {
    return props;
};
export default connect(mapStateToProps, null)(CountryComponent);
export const query = graphql`
 fragment UKFragment on MarkdownRemark {
      frontmatter {
      title
      description
    }
  }
  query UKPageQuery {
    seo: markdownRemark(frontmatter: {templateKey: {eq: "wld-uk-seo"}}) {
        frontmatter {
            title
            description
        }
  }
  enUK: markdownRemark(frontmatter: {templateKey: {eq: "en-wld-uk"}}) {
    ...UKFragment
  }
  deUK: markdownRemark(frontmatter: {templateKey: {eq: "de-wld-uk"}}) {
    ...UKFragment
  }
  esUK: markdownRemark(frontmatter: {templateKey: {eq: "es-wld-uk"}}) {
    ...UKFragment
  }
  frUK: markdownRemark(frontmatter: {templateKey: {eq: "fr-wld-uk"}}) {
    ...UKFragment
  }
  heUK: markdownRemark(frontmatter: {templateKey: {eq: "he-wld-uk"}}) {
    ...UKFragment
  }
  itUK: markdownRemark(frontmatter: {templateKey: {eq: "it-wld-uk"}}) {
    ...UKFragment
  }
  jaUK: markdownRemark(frontmatter: {templateKey: {eq: "ja-wld-uk"}}) {
    ...UKFragment
  }
  zhUK: markdownRemark(frontmatter: {templateKey: {eq: "zh-wld-uk"}}) {
    ...UKFragment
  }
 }
`;
